/**
 * @ The external dependencies.
*/
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import { useToasts } from 'react-toast-notifications';
/**
 * @ The internal dependencies.
*/
import { closeModal } from '../../store/state/ui/actions';
import CopyIcon from '../../assets/svg/copy-icon.svg';
import EmailIcon from '../../assets/svg/email-icon.svg';
import SmsIcon from '../../assets/svg/sms-icon.svg';
import IconSvg from '../../components/common/icon-svg';

const ModalSplitQRCode = (props) => {
    // const { splitType, splitAmount } = props.data;
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const cartToken = props.data.token || useSelector(state => state.cart.data.cartToken);

    const copyToClipboard = txt => {
        const el = document.createElement('textarea');
        el.value = txt;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '9999px';
        document.body.appendChild(el)
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
    const path = window.location.pathname.split('/');
    const posId = path.length > 2 ? path[1] : null;
    const waypointId = path.length > 2 ? path[2] : null;

    return (
        <div className="modal-frame modal-frame--card">
            <div className="modal-frame__body">
                <div className="modal-frame__card">
                    <div className="modal-frame__head">
                        <h6 className="modal-frame__title">{window.resources.qr_code_invite.invite_msg_text}</h6>
                    </div>
                    <div className="modal-frame__actions modal-frame__actions--flex column-actions">
                        <div><QRCode value={`${window.location.origin}/${posId}/${waypointId}/split/invite/${props.data.splitType}/${parseFloat(props.data.inviteAmount).toFixed(2)}?ct=${encodeURIComponent(cartToken)}`} /></div>
                        <div className='qr-alt-container'>
                            <div>{window.resources.qr_code_invite.go_to_text}</div>
                            <a href={`${window.location.origin}/${posId}/${waypointId}/split/invite/${props.data.splitType}/${parseFloat(props.data.inviteAmount).toFixed(2)}?ct=${encodeURIComponent(cartToken)}`} target='_blank'>{`${window.location.origin}/${posId}/${waypointId}/split/invite/`}</a>
                            <div className='alternatives'>
                                <div className='item' 
                                     onClick={() => {
                                         copyToClipboard(`${window.location.origin}/${posId}/${waypointId}/split/invite/${props.data.splitType}/${parseFloat(props.data.inviteAmount).toFixed(2)}?ct=${encodeURIComponent(cartToken)}`);
                                         addToast(<div>Copied to Clipboard</div>, { appearance: 'info', autoDismiss: true })
                                     }
                                }>
                                    <div className='icon'><IconSvg src={CopyIcon} /></div> 
                                    <div>{window.resources.qr_code_invite.copy_url_text}</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><IconSvg src={EmailIcon} /></div>
                                    <div><a href={`mailto:?body=${window.location.origin}/${posId}/${waypointId}/split/invite/${props.data.splitType}/${parseFloat(props.data.inviteAmount).toFixed(2)}?ct=${encodeURIComponent(cartToken)}`}>EMAIL</a></div>
                                </div>
                                {/* <div className='item'>
                                    <div className='icon'><IconSvg src={SmsIcon} /></div>
                                    <div><a href={`sms:?body=${window.location.origin}/${posId}/${waypointId}/split/invite/${props.data.splitType}/${props.data.inviteAmount}?ct=${encodeURIComponent(cartToken)}`}>SMS</a></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='modal-frame modal-frame__foot'>
                        <h6 className="modal-frame__title" onClick={() => dispatch(closeModal())}>{window.resources.qr_code_invite.ok_text}</h6>
                    </div>
                </div>
            </div>
        </div>
     )
}

export default ModalSplitQRCode;