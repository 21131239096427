/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';

import {
    startAddToCart,
    acknowledgeAlcohol
} from 'store/state/cart/actions';

/**
 * Class for modal alcohol.
 *
 * @class ModalAlcohol (name)
 */
class ModalAlcohol extends Component {

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.props.closeModal();

        this.props.acknowledgeAlcohol();

        this.props.startAddToCart({
            products: this.props.products,
            showSuggestions: this.props.showSuggestions, 
            ga: this.props.ga, 
            currencyCode: this.props.config.isoCurrencyCode
        }); 
    }

    replaceVariables(html) {
        return html.replace("className", "class");
    }

	render() {
		return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h5 className="modal-frame__title">{window.resources.alcohol.label_text}</h5>
                        </div>

                        <div className="modal-frame__card-body editable" dangerouslySetInnerHTML={{ __html: window.resources.alcohol.html }}>

                        </div>
                        <br />
                        <div className="modal-frame__actions modal-frame__actions--flex">
                            {window.resources.alcohol.cancel_button && <button
                                onClick={() => this.props.closeModal()}
                                className="btn btn--secondary">{window.resources.alcohol.cancel_text}</button>}

                            {!window.resources.alcohol.cancel_button && <button
                                onClick={() => this.props.closeModal()}
                                className="btn btn--secondary btn-yes editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.alcohol.cancel_html) }}></button>}

                            <button
                                onClick={this.handleSubmitButton}
                                className="btn btn-yes editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.alcohol.confirm_html) }}></button>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

const mapStateToProps = state => {
    return {
        cartPricingData: state.cart.cartPricingData,
        allProducts: state.app.data.inventoryItemMains,
        cartItems: state.cart.data.arrCart,
        products: state.ui.modal.data.products,
        showSuggestions: state.ui.modal.data.showSuggestions, 
        ga: state.app.ga, 
        config: state.app.config
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        openModal,
        closeModal,
        startAddToCart,
        acknowledgeAlcohol
	}
)(ModalAlcohol));
