/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest,
    select,
    put
} from 'redux-saga/effects';

import { sendTableCheckRequest, receiveTableCheckResponse } from './actions';
import { requestTableTopData, requestTableTopConfig } from 'store/state/app/actions';
import makeRequest from '../../../lib/helpers/api-request';


export function* sendTableCheckRequestWorker(action) {
    const payload = {
        getOpenTickets: action.payload.getOpenTickets,
        locationName: action.payload.tableNumber,
        showSpinner: action.payload.showSpinner
    };

    yield call(makeRequest, {
        endpoint: 'tableCheck',
        payload: payload,
        requestAction: action,
        receiveAction: receiveTableCheckResponse
    });

    //After doing a table check refresh the tabletop data if the platformType changes
    const { tablecheck } = yield select();
    const platformType = tablecheck.urlTableNumber.toLowerCase().endsWith('vk') ? 7 : 3;
    if (platformType !== window.platformType) {
        window.platformType = platformType;
        yield all([
            put(requestTableTopConfig({ performOrderCalc: false })),
            put(requestTableTopData())
        ]);
    }
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(sendTableCheckRequest, sendTableCheckRequestWorker)
    ]);
}
