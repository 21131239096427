/**
 * @ The internal dependecies.
 */
import SubstantialMealIcon from '../../assets/svg/substantial-meal-icon.svg';
import IconSvg from 'components/common/icon-svg';
import GlutenFree from '../../assets/svg/nutrition/nutrition_icon_gluten_free_color.svg';
import Halal from '../../assets/svg/nutrition/nutrition_icon_halal_color.svg';
import Healthy from '../../assets/svg/nutrition/nutrition_icon_healthy_color.svg';
import Kosher from '../../assets/svg/nutrition/nutrition_icon_kosher_color.svg';
import Organic from '../../assets/svg/nutrition/nutrition_icon_organic_color.svg';
import SugarFree from '../../assets/svg/nutrition/nutrition_icon_sugar_free_color.svg';
import Vegan from '../../assets/svg/nutrition/nutrition_icon_vegan_color.svg';
import Vegetarian from '../../assets/svg/nutrition/nutrition_icon_vegetarian_color.svg';
import Dairy from '../../assets/svg/nutrition/nutrition_icon_dairy_color.svg';
import Eggs from '../../assets/svg/nutrition/nutrition_icon_eggs_color.svg';
import Peanut from '../../assets/svg/nutrition/nutrition_icon_peanut_color.svg';
import Soy from '../../assets/svg/nutrition/nutrition_icon_soy_color.svg';
import Treenut from '../../assets/svg/nutrition/nutrition_icon_treenut_color.svg';
import Wheat from '../../assets/svg/nutrition/nutrition_icon_wheat_color.svg';
import Chili from '../../assets/svg/nutrition/nutrition_icon_chili_color.svg';

/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependecies.
 */
import { localizeNumber } from '../../assets/resources/resources-manager';
import Timer from '../../components/daypart/timer';

/**
 * Class for menu product.
 *
 * @class MenuProduct (name)
 */
class MenuProduct extends Component {
    state = {
        productImageError : null
    }

	totalSelectedCount(id) {
		let totalCount = 0;

		// eslint-disable-next-line
		this.props.selectedProducts.find(product => {
            if (product.inventoryItemID === id) {
                totalCount = totalCount + product.quantity;
			}
		});

		return totalCount !== 0 ? totalCount : null;
    }

    hasSubstantialTag() {
        if (this.props.alcoholMealLimitEnabled === false) return false;
        if (this.props.product.inventoryTags == undefined || this.props.product.inventoryTags.length == 0) return false;
        for (let tag of this.props.product.inventoryTags) {
            if (tag.tagDescription == 'Substantial Meal') {
                return true;
            }
        }

        return false;
    }

    render() {
        const imgUrl = this.props.product.imageV2 && encodeURI(this.props.product.imageV2.product_1_1 || this.props.product.inventoryItemImageName);
        return (
            <Link
                to={`/menu/${this.props.product.inventoryItemID}`}
                id={this.props.product.inventoryItemID}
                onClick={this.props.onItemClick(this.props.inventoryTitleID, this.props.product.inventoryItemID, this.props.product.inventoryItemAvailableAndInsideTimeWindow)}
                className={"item-menu item-menu--nested" + (this.props.product.inventoryItemAvailableAndInsideTimeWindow ? "" : " item-disabled")}>
                {this.totalSelectedCount(this.props.product.inventoryItemID) && (
                    <span className="item-menu__batch">{localizeNumber(this.totalSelectedCount(this.props.product.inventoryItemID))}</span>
                )}

                <div className={(!imgUrl || (this.state.productImageError)) ? 'item-menu__content-full-width' : 'item-menu__content'}>
                    <h6 className="item-menu__title">{this.props.product.inventoryItemName} {this.props.showCalories && this.props.product.inventoryItemSubs.length === 1 && this.props.product.inventoryItemSubs[0].calorieDisplay && '(' + this.props.product.inventoryItemSubs[0].calorieDisplay + ' ' + window.resources.menu.calories_text + ')'}</h6>

                    <div className="item-menu__entry">
                        <p className="truncated">{this.props.product.inventoryItemDescription || '\u00A0'}</p>
                    </div>

                    <div className="item-menu__meta">
                        <p className="item-menu__price">
                            <span>{this.props.isCombo && (this.props.product.inventoryItemSubs.length === 0 || this.props.product.inventoryItemSubs[0].comboCost === 0.00 ? localizeNumber(window.resources.menu.zero_text) : localizeNumber(this.props.product.inventoryItemSubs[0].comboCost))}{this.props.isCombo && (this.props.product.inventoryItemSubs.length > 1 || (this.props.product.inventoryItemName && this.props.product.inventoryItemName.includes('Combo')) ? '+' : '')}
                                {!this.props.isCombo && (this.props.product.inventoryItemSubs[0].costDisplay.trim() === '0.00' ? localizeNumber(window.resources.menu.zero_text) : localizeNumber(this.props.product.inventoryItemSubs[0].costDisplay.trim()))}{!this.props.isCombo && (this.props.product.inventoryItemSubs.length > 1 ? '+' : '')}
                            </span>

                            {this.props.product.inventoryFeatureItem && (
                                <span className="item-menu__label item-tag-popular">
                                    <FontAwesomeIcon icon={faStar} />

                                    <span>{window.resources.menu.popular_text}</span>
                                </span>
                            )}
                            {this.hasSubstantialTag() && (
                                <span className="item-menu__label item-tag-substantial">
                                    <IconSvg src={SubstantialMealIcon} />
                                    <p>{`${window.resources.menu.substantial_meal_text}*`}</p>
                                </span>
                            )}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'gluten free') && <span className="item-menu__label"><IconSvg src={GlutenFree} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'halal') && <span className="item-menu__label"><IconSvg src={Halal} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'healthy') && <span className="item-menu__label"><IconSvg src={Healthy} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'kosher') && <span className="item-menu__label"><IconSvg src={Kosher} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'organic') && <span className="item-menu__label"><IconSvg src={Organic} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'sugar free') && <span className="item-menu__label"><IconSvg src={SugarFree} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'vegan') && <span className="item-menu__label"><IconSvg src={Vegan} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'vegetarian') && <span className="item-menu__label"><IconSvg src={Vegetarian} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'dairy') && <span className="item-menu__label"><IconSvg src={Dairy} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'eggs') && <span className="item-menu__label"><IconSvg src={Eggs} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'peanuts') && <span className="item-menu__label"><IconSvg src={Peanut} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'soy') && <span className="item-menu__label"><IconSvg src={Soy} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'tree nuts') && <span className="item-menu__label"><IconSvg src={Treenut} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'wheat') && <span className="item-menu__label"><IconSvg src={Wheat} /></span>}
                            {this.props.product.inventoryTags && this.props.product.inventoryTags.find(tag => tag.tagDescription.toLowerCase() === 'chili') && <span className="item-menu__label"><IconSvg src={Chili} /></span>}
                        </p>
                    </div>
                </div>

                {!(!imgUrl || (this.state.productImageError)) ? 
                    <React.Fragment>
                        {   this.props.product.showDayPartIcon && this.props.inRange && 
                            <div style={{ position: 'relative', width: '100%' }}>
                                <Timer hasImage={true} />
                            </div>
                        }
                        <figure
                            className="item-menu__image"
                            style={{
                                backgroundImage: `url('${imgUrl}')`
                            }}>
                            <img src={imgUrl} style={{ display: 'none' }} alt="productImage" onError={(e) => {
                                if (imgUrl) {
                                    this.setState(prevState => {
                                        return {
                                            productImageError: true
                                        };
                                    });
                                }
                            }} />
                        </figure>
                    </React.Fragment> : 
                    <React.Fragment>
                    {   this.props.product.showDayPartIcon && this.props.inRange && 
                        <div style={{ position: 'relative', width: '100%' }}>
                            <Timer hasImage={false} />
                        </div>
                    }
                    </React.Fragment>
                }
            </Link>
        )
	}
}

export default MenuProduct;
