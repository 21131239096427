import config from 'config';

const MultiConceptAssetHeaderLink = ({ id, rel, type, as, path }) => {

    const hrefs = [config.REACT_APP_MULTI_CONCEPT_ASSETS_URL + window.multiConceptID + (path.startsWith('/') ? '' : '/') + path];

    let index = hrefs.length - 1;
    const linkElement = document.createElement("link");
    if (id)
        linkElement.id = id;
    if (type)
        linkElement.type = type;
    if (rel)
        linkElement.rel = rel;
    if (as)
        linkElement.as = as;

    linkElement.href = hrefs[index];
    linkElement.onerror = () => {
        if (index - 1 >= 0) {
            index = index - 1;
            linkElement.href = hrefs[index];
        }
    }

    document.head.appendChild(linkElement);
};

export default MultiConceptAssetHeaderLink;