/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'ramda';

/**
 * @ The internal dependencies.
 */
import IconSvg from 'components/common/icon-svg';
import StoreAssetSvg from 'components/common/store-asset-svg';
import BagIcon from '../../assets/svg/bag_icon_white.svg';
import CrownIcon from '../../assets/svg/crown_white.svg';
import SelectLanguage from 'components/localization/select-language';
import { hasTabOpen } from '../../store/state/tab/selectors';
import { loyaltyLoggedIn } from '../../store/state/loyalty/selectors';
import { showLanguageWidget } from 'lib/helpers/language';
import * as features from '../../store/state/features/selectors';
import { openModal } from 'store/state/ui/actions';
import { MODAL_WARNING, MODE_ORDER } from 'lib/constants';
import config from '../../config';

/**
 * Class for header.
 *
 * @class Header (name)
 */
class Header extends Component {
	state = {
		searchIsVisible: false
	}

	floorFigure(figure, decimals = 2) {
		var d = Math.pow(10, decimals);
		return (parseInt(figure * d, 10) / d).toFixed(decimals);
	};

	handleMultiConceptButton = (e) => {
		e.preventDefault();

		const multiConceptUrl = window.location.protocol + "//" + window.location.host + "/" + window.posID + "/multi/" + this.props.multiConceptID + `/${this.props.tableNumber}`;

		if (this.props.showMultiConceptWarning) {
			this.props.openModal({
				type: MODAL_WARNING,
				data: {
					allowRedirect: true,
					message: window.resources.cart.multiconcept_html,
					btnText: window.resources.cart.confirm_multiconcept_text,
					btnTextCancel: window.resources.cart.cancel_multiconcept_text,
					fun: () => {
						window.location.href = multiConceptUrl;	
					},
					funCancel: () => {

					}
				}
			});
		}
		else {
			window.location.href = multiConceptUrl;	
		}
	}

	handleBackButton = (e) => {
		e.preventDefault();

		this.props.history.goBack();
	}


	render() {
		return (
            <header className="header" style={{ top: this.props.top + 'px' }}>
				<div className="shell">
                    <div className="header__content">
						<div>
							{this.props.multiConceptFeatureEnabled && <button
								onClick={this.handleMultiConceptButton}
								className="header__btn" aria-label={window.resources.navigation.home_text}>
								<i className="fas fa-home" title={window.resources.navigation.home_text}></i>
							</button>}
                            <button
								tabIndex={0}
                                onClick={this.handleBackButton}
								className="header__btn header__btn-back" aria-label={window.resources.navigation.back_text}>
								<i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                            </button>

                            {showLanguageWidget() && <SelectLanguage showDescription={false} inline={true} />}
                        </div>

						<Link to="/categories" className="header__logo" aria-label={window.resources.navigation.categories_text}>
							<StoreAssetSvg title={window.resources.navigation.categories_text} path='logo-menu.svg'/>
						</Link>

						<div style={{ display: 'flex', position:'relative' }}>
							{this.props.loyaltyLoggedIn && <div className="header__btn" style={{fontWeight: 'bold', position: 'absolute', width: '110px', right: '35px'}}>
								<img src={CrownIcon} style={{ width: '31px', marginRight: '5px' }} alt="RR" /> <span style={{fontSize: '18px', position: 'absolute', bottom: '3px'}}>{this.props.currencySymbol}{this.floorFigure(this.props.balance)}</span>
							</div>}

							{this.props.searchFeatureEnabled && <Link
								to="/search"
								className="header__btn" aria-label={window.resources.navigation.search_text}>

								<i className="fas fa-search" title={window.resources.navigation.search_text}></i>
							</Link>}
			
						{this.props.mode === MODE_ORDER ? <Link
								to="/cart"
								aria-label={window.resources.cart.title_text}
							className="header__btn header__btn-cart">
							{!isEmpty(this.props.arrCart) && (
								<span className="dot"></span>
							)}
								{window.resources.header.bag_icon ? <IconSvg src={BagIcon} /> : <FontAwesomeIcon aria-labelledby="" title={window.resources.cart.title_text} icon={faShoppingBasket} />}
							</Link> : <Link
									style={{ visibility: 'hidden' }}
								to="/cart"
								className="header__btn header__btn-cart">
									<FontAwesomeIcon icon={faShoppingBasket} />
								</Link>}
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		mode: state.cart.mode,
		showMultiConceptWarning: true /*state.cart.data && state.cart.data.arrCart && state.cart.data.arrCart.length > 0*/,
		multiConceptFeatureEnabled: features.multiConceptFeatureEnabled(state),
		searchFeatureEnabled: features.searchFeatureEnabled(state),
		multiConceptID: state.app.config && state.app.config.MultiConceptID,
		tableNumber: state.checkout.tableNumber,
		top: 0 + (hasTabOpen(state) ? 62 : 0),
		arrCart: state.cart.data.arrCart,
		loyaltyLoggedIn: loyaltyLoggedIn(state),
		currencySymbol: state.app.currencySymbol,
		balance: state.loyalty.balance,
	}), {
		openModal
	}
)(Header));
