/**
 * @ The external dependencies.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';
import CacheBuster from 'react-cache-buster';

/**
 * @ The internal dependencies.
 */

import { store, persistor } from './store/store';
import ScrollToTop from 'lib/helpers/scroll-to-top';
import AppStore from 'views/application/app-store';
import AppMultiConcept from 'views/application/app-multi-concept';
import InvalidStore from './components/error/invalid-store';
//import App from 'views/application/app';
import Loader from 'components/loader/loader';
import * as queryString from 'lib/helpers/query-string';
import config from './config';
import * as iframeCommands from './iframe-commands';
import * as mutations from 'lib/helpers/mutations';
import { InfoToast } from './components/toast/toast';
import { version } from '../package.json';
import { getSessionCustomer, setSessionCustomer, getSessionStyle, setSessionStyle } from 'lib/helpers/local-storage';
import { CreateStoreAssetHeaderLink, CreateStoreCSSAssetHeaderLink } from 'components/common/store-asset-header-link';
import CreateMultiConceptAssetHeaderLink from 'components/common/multi-concept-header-link';

/**
 * @ Load styles.
 */
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets/css/_main.css';
import { getLanguage, getLanguageMultiConcept, getCurrentLanguageDirection, setTextDirectionAttribute, setLanguageAttribute } from './lib/helpers/language';
import * as resourcesManager from 'assets/resources/resources-manager';
import * as languagesManager from 'assets/languages/languages-manager';
import smoothscroll from 'smoothscroll-polyfill';
import { unregister } from './utils/registerServiceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

if (config.SENTRY_ENABLED) {
    Sentry.init({
        dsn: "https://c8ba9aed39df43bcb50742cc4d780a95@o977893.ingest.sentry.io/5934212",
        integrations: [new Integrations.BrowserTracing(),
        new CaptureConsoleIntegration(
            {
                // array of methods that should be captured
                // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['error']
            }
        )],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
        sampleRate: 0.1,
        environment: config.SENTRY_ENVIRONMENT
    });
}

smoothscroll.polyfill();

//By default React Apps like to served from the root of a domain because of client side routing. e.g https://orderandpay.net
//These settings allow the React App to be served from https://orderandpay.net/12345
let allowRootDomainAccess = true;
let splitPath = window.location.pathname.split("/");
let posID = splitPath[1];
let waypointID;
let multiConceptID;
let validUrl = true;


//var urlParams = new URLSearchParams(window.location.search);
//if (urlParams.has('openTabEnabled')) {
//    window.openTabBypass = urlParams.get('openTabEnabled');
//}
//else {
//    window.openTabBypass = false;
//}

if (splitPath.length >= 2 && !posID.toLowerCase().startsWith("p")) {
    if (posID === '26439' || posID === '30036' || posID === '26732' || posID === '28006' || posID === '10000' || posID === '30000') {
        waypointID = posID;
        posID = "p2";
    }
    else if (posID === 'multi') {
        waypointID = 'multi';
        multiConceptID = splitPath[2] || "";
        posID = "";
    }
    else {
        waypointID = 'INVALID';
        validUrl = false;
    }
}
else if (splitPath.length >= 3) {
    waypointID = splitPath[2];
    if (waypointID === 'multi') {
        multiConceptID = splitPath[3] || "";
    }
}
else {
    validUrl = false;
    waypointID = 'INVALID';
}

//let waypointID = splitPath[1];
let baseName = "/";
let multiConcept = false;
window.posID = posID;

if (waypointID === 'multi') {
    //1. MultiConcept App
    //path structure: /{posID}/multi/{multiConceptID}

    multiConcept = true;

    baseName = (posID ? `/${posID}` : '') + "/multi" + (multiConceptID !== "" ? "/" + multiConceptID : "");

    window.kobp = "grab_tabletop";
    window.xpID = '';
    window.multiConceptID = multiConceptID;
    window.platformType = 3;
    window.gID = '';

    // Load multi-concept theme
    CreateMultiConceptAssetHeaderLink({ rel: 'preload', as: 'style', path: '/styles.css' });
    CreateMultiConceptAssetHeaderLink({ rel: 'stylesheet', type: 'text/css', path: '/styles.css' });

    // Override multi-concept theme from querystring
    const css = document.createElement("style");
    css.id = 'styles';
    css.type = 'text/css';
    document.head.appendChild(css);

    //Download languages.js, set window.language, then download resources.{language}.js
    const setupLanguagesCallback = () => {
        window.language = getLanguageMultiConcept();
        setTextDirectionAttribute(getCurrentLanguageDirection());
        setLanguageAttribute(window.language.substring(0, 2));
        resourcesManager.setupResourcesMultiConcept([multiConceptID], window.language);
        render(); 
    };
    languagesManager.setupLanguagesMultiConcept([multiConceptID], setupLanguagesCallback, setupLanguagesCallback);
    window.resources = { spinners: { loading_text: 'Loading' }};
}
else {
    //2. Store App
    //path structure: /{posID}/{waypointID}/{tableID}
    let hostname = window.location.hostname;
    const domainToStoreMapping = {
        //'localhost': '20000'
    };

    if (domainToStoreMapping[hostname]) {
        waypointID = domainToStoreMapping[hostname];
        baseName = "/" + posID + "/";
    }
    else {
        ['categories', 'menu', 'cart', 'checkout', 'tab', /*, /^[0-9]{1,3}$/*/].forEach(route => { if (waypointID.search(route) !== -1) { waypointID = "" } });
        baseName = "/" + posID + "/" + waypointID;
    }

    window.kobp = "grab_tabletop_" + waypointID;
    const style = getSessionStyle(window.kobp)
    window.waypointID = waypointID;
    window.platformType = window.location.pathname.toLowerCase().endsWith('vk') ? 7 : 3;

    //right to left e.g [1000, ex_3000]
    //Will try ex_3000 first and fallback to 1000 for each of the following store-asset files
    //store-assets/{waypointID/experienceID}/languages.json
    //store-assets/{waypointID/experienceID}/resources.json
    //store-assets/{waypointID/experienceID}/resources.{lang}.json
    //store-assets/{waypointID/experienceID}/logo.svg
    //store-assets/{waypointID/experienceID}/logo-menu.svg
    //store-assets/{waypointID/experienceID}/styles.css
    const styleWaypointIDs = [waypointID];

    let styleUpdated = false;

    let xpParam = queryString.getValue('xp');
    if (xpParam) {
        styleUpdated = true;
        style.xpID = `xp_${xpParam}`;
        style.waypointID = '';
    }
    else if (xpParam === '') {
        styleUpdated = true;
        style.xpID = '';
        style.waypointID = '';
    }

    let styleParam = queryString.getValue('style');
    if (styleParam) {
        styleUpdated = true;
        style.xpID = '';
        style.waypointID = styleParam;
    }
    else if (styleParam === '') {
        styleUpdated = true;
        style.xpID = '';
        style.waypointID = '';
    }

    if (styleUpdated) {
        setSessionStyle(style);
    }

    if (style.waypointID) {
        styleWaypointIDs.push(style.waypointID);
    }
    else if (style.xpID) {
        window.xpID = style.xpID;
        styleWaypointIDs.push(style.xpID);
    }

    window.styleWaypointIDs = styleWaypointIDs;

    let customerUpdated = false;
    const customer = getSessionCustomer() || {};
    let gid = (customer && customer.kobp === window.kobp && customer.gID) || '';
    let gidParam = queryString.getValue('gid');
    if (gidParam) {
        customerUpdated = true;
        gid = gidParam;
    }
    else if (gidParam === '') {
        customerUpdated = true;
        gid = '';
    }

    if (customerUpdated) {
        customer.kobp = window.kobp;
        customer.gID = gid;
        setSessionCustomer(customer);
    }

    window.gID = gid;

    // Load store theme
    CreateStoreCSSAssetHeaderLink({ rel: 'preload', as: 'style', path: '/styles.css' });
    CreateStoreCSSAssetHeaderLink({ rel: 'stylesheet', type: 'text/css', path: '/styles.css' });

    // Override store theme from querystring
    const css = document.createElement("style");
    css.id = 'styles';
    css.type = 'text/css';
    document.head.appendChild(css);

    //Download languages.js, set window.language, then download resources.{language}.js
    const setupLanguagesCallback = () => {
        window.language = getLanguage();
        setTextDirectionAttribute(getCurrentLanguageDirection());
        setLanguageAttribute(window.language.substring(0, 2));
        resourcesManager.setupResources(window.styleWaypointIDs, window.language);
        render();
    };
    languagesManager.setupLanguages(window.styleWaypointIDs, setupLanguagesCallback, setupLanguagesCallback);
    window.resources = { spinners: { loading_text: 'Loading' } };

    //prefetch logo-menu image
    CreateStoreAssetHeaderLink({ rel: 'prefetch', as: 'prefetch', path: '/logo-menu.svg' });
}

window.preview = queryString.getValue('preview') === 'true' ? true : false;
if (window.preview) {
    window.opentab = queryString.getValue('opentab') === 'true' ? true : false;
    iframeCommands.initialize();
    mutations.initialize();
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

let renderApp = validUrl && (allowRootDomainAccess || baseName !== '/');

/**
 * @ Start the application.
 */

const render = () => {
    const appClientStateLoader = (<div className="wrapper">
        <Loader title={window.resources.spinners.loading_text} />
    </div>)

    const reactApp = (
        <CacheBuster
            currentVersion={version}
            isEnabled={true}
            isVerboseMode={false}
            loadingComponent={appClientStateLoader}
        >
        <Provider store={store}>
            <PersistGate loading={appClientStateLoader} persistor={persistor}>
                <BrowserRouter basename={baseName}>
                    <ToastProvider autoDismiss autoDismissTimeout={2000} placement='top-center' components={{ Toast: InfoToast}} >
                        <ScrollToTop>
                            {multiConcept ? <AppMultiConcept /> : <AppStore />}
                        </ScrollToTop>
                    </ToastProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
        </CacheBuster>);

    const invalidStore = (<div className="wrapper">
        <InvalidStore />
    </div>);

    return ReactDOM.render(renderApp ? reactApp : invalidStore, document.getElementById('root'));
};

const renderLoader = () => {
    const loader = (<div className="wrapper">
        <Loader title={window.resources.spinners.loading_text} />
    </div>)

    ReactDOM.render(loader, document.getElementById('root'));
};

renderLoader();

/**
 * @ Enable Hot Module Replacement
 */
if (module.hot && process.env.NODE_ENV !== 'production') {
    console.log('HMR Enabled for Components');
    module.hot.accept('views/application/app-store', () => {
        console.log('HMR App Store');
        render();
    });
    module.hot.accept('views/application/app-multi-concept', () => {
        console.log('HMR App MultiConcept');
        render();
        //
    });
}

/**
 * @ Initialize service worker.
 */
unregister();
