let config;


const hostname = window && window.location && window.location.hostname.toLowerCase();

if (hostname.endsWith('orderandpaystage.com')) {
    //staging
    config = {
        REACT_APP_STORE_CSS_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_ASSETS_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: 'https://grabmobilestagev2.com/Tabletopassets/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: 'https://grabmobilestagev2.com/Tabletopassets/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: 'https://grabmobilestagev2.com/Tabletopassets/multi-concept-assets/',
        REACT_APP_LANGUAGES_URL: process.env.PUBLIC_URL + '/languages/',
        REACT_APP_RESOURCES_URL: process.env.PUBLIC_URL + '/resources/',
        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'STAGE',
        SENTRY_ENVIRONMENT: 'STAGE',
        SENTRY_ENABLED: true
    };
}
else if (hostname.includes('localhost')) {
    // Localhost/DEV
    config = {
        REACT_APP_STORE_CSS_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_ASSETS_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_LANGUAGES_URL: process.env.PUBLIC_URL + '/languages/',
        REACT_APP_RESOURCES_URL: process.env.PUBLIC_URL + '/resources/',
        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'STAGE',
        SENTRY_ENVIRONMENT: 'DEV_LOCAL',
        SENTRY_ENABLED: false
    };
}
else {
    //Production
    config = {
        REACT_APP_STORE_CSS_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_ASSETS_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_LANGUAGES_URL: process.env.PUBLIC_URL + '/languages/',
        REACT_APP_RESOURCES_URL: process.env.PUBLIC_URL + '/resources/',
        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'PRODUCTION', 
        SENTRY_ENVIRONMENT: 'PRODUCTION',
        SENTRY_ENABLED: true
    };
    if (hostname.toLocaleLowerCase().includes('uat')) {
        config.ENVIRONMENT = 'UAT'
        config.SENTRY_ENVIRONMENT = 'UAT'
    }
}

export default config