/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import {
    compose,
    sortBy,
    prop,
    pickAll,
    isEmpty,
    clone
} from 'ramda';

/**
 * @ The internal dependecies.
 */
import Header from 'components/header/header';
import ProductSizes from 'views/combo/combo-sizes';
import ProductChoices from 'views/combo/combo-choices';
import ProductOptions from 'views/combo/combo-options';
import ProductQuantity from 'views/combo/combo-quantity';
import MenuItem from 'views/menu/menu-item';

import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import {
    startAddToCart,
    updateCartTotal,
    updateModifiers,
    updateCartProducts,
    clearEditProduct,
    startCartUpdate
} from 'store/state/cart/actions';
import { openModal } from 'store/state/ui/actions';
import { MODAL_ALCOHOL, MODAL_WARNING, MODE_ORDER } from 'lib/constants';
import { clearSearch } from 'store/state/search/actions';

import { updateObject } from '../../lib/utility';
import * as features from '../../store/state/features/selectors'

/**
 * Class for product.
 *
 * @class Product (name)
 */
class Combo extends Component {
    state = {
        idx: 0,
        products: [],
        combo: {},
        comboGroupDetails: [],
        comboOrderID: null,
        editMode: true,
        imageError: false
    }

    //Lifecycle events
    componentWillMount() {
        const comboID = this.props.match.params.comboID;
        let comboOrderID = this.props.match.params.comboOrderID;

        const idx = 0;
        const combo = this.props.data.comboEngine.comboGroups.find(item => item.comboID === comboID);
        const comboGroupDetails = [...combo.comboGroupDetails].sort((a, b) => Number(a.inventoryItemDisplayOrder) > Number(b.inventoryItemDisplayOrder));
        const firstComboGroupDetail = comboGroupDetails[idx];
        const editMode = !firstComboGroupDetail.bIsComboChoiceGroup;

        if (comboOrderID) {
            const products = clone(this.props.arrCart.filter(x => x.comboOrderID === comboOrderID));
            this.setState({
                idx: idx,
                products: products,
                combo: combo,
                comboGroupDetails: comboGroupDetails,
                comboOrderID: comboOrderID,
                editMode: editMode,
                originalQty: products[0].quantity,
            });
        } else {
            comboOrderID = this.generateComboOrderID(10);
            const products = Array(combo.comboGroupDetails.length).fill(null);
            if (editMode) {
                products[0] = this.setProductDefaults(clone(this.props.data.inventoryItemMains.find(item => item.inventoryItemID === firstComboGroupDetail.inventoryItemID)), comboID, comboOrderID, firstComboGroupDetail.comboChoiceGroupID);
            }

            this.setState({
                idx: idx,
                products: products,
                combo: combo,
                comboGroupDetails: comboGroupDetails,
                comboOrderID: comboOrderID,
                editMode: editMode
            });
        }
    }

    generateComboOrderID = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    setProductDefaults(product, comboID, comboOrderID, comboChoiceGroupID) {
        if (product.inventoryItemSubs.length === 1) {
            sortBy(compose(parseInt, prop('inventoryOrder')), product.inventoryItemSubs)[0].selected = true;
        }
        product.quantity = 1;
        product.comboID = comboID;
        product.comboOrderID = comboOrderID;
        product.comboChoiceGroupID = comboChoiceGroupID;
        return product;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.state.originalQty && this.props.editableProduct && this.state.originalQty !== this.props.editableProduct.quantity) {

            const updatedProduct = updateObject(this.state.products[this.state.idx], {
                quantity: this.props.editableProduct.quantity
            });

            this.setState({
                originalQty: this.props.editableProduct.quantity,
                products: [
                    ...this.state.products.slice(0, this.state.idx),
                    updatedProduct,
                    ...this.state.products.slice(this.state.idx + 1)
                ]
            });
        }
    }

    componentWillUnmount() {
        this.props.clearEditProduct();
    }

    //Product Update - Size
    handleSizeChange = ({ index, id }) => (e) => {
        if (this.props.mode === MODE_ORDER) {
            const updateIndex = this.state.products[this.state.idx].inventoryItemSubs.findIndex(x => x.inventoryItemSubID === id);

            const updatedInventoryItemSubs = clone(this.state.products[this.state.idx].inventoryItemSubs);

            const updatedInventoryItemSub = updatedInventoryItemSubs[updateIndex];
            updatedInventoryItemSub.selected = !updatedInventoryItemSub.selected;

            if (updatedInventoryItemSub.selected) {
                updatedInventoryItemSubs.filter(x => x.selected && x.inventoryItemSubID !== id).forEach((inventoryItemSub, i) => {
                    inventoryItemSub.selected = false;

                });
            }
            else {

            }

            const updatedProduct = updateObject(this.state.products[this.state.idx], {
                inventoryItemSubs: updatedInventoryItemSubs
            });

            this.setState({
                products: [
                    ...this.state.products.slice(0, this.state.idx),
                    updatedProduct,
                    ...this.state.products.slice(this.state.idx + 1)
                ]
            });
        }
    }

    //Product Update - Choice and Option
    handleCheckboxChange = ({
        prop,
        itemsCollectionName,
        collectionName,
        parentIdx,
        itemIdx,
        limit
    }) => (event) => {
        if (this.props.mode === MODE_ORDER) {
            const product = {
                ...this.state.products[this.state.idx]
            };

            const selection = this.getSelection(collectionName, itemsCollectionName, parentIdx);

            if (product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx].selected) {
                product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx].selected = false;

            } else {
                product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx].selected = true;
            }

            if (
                limit > 0 &&
                product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx].selected &&
                selection.length >= limit
            ) {
                this.limitSelection(prop, itemIdx, collectionName, itemsCollectionName, parentIdx);
                return;
            }

            this.setState({
                products: [
                    ...this.state.products.slice(0, this.state.idx),
                    product,
                    ...this.state.products.slice(this.state.idx + 1)
                ]
            });
        }
    }

    limitSelection(prop, itemIdx, collectionName, itemsCollectionName, parentIdx) {
        const selection = this.getSelection(collectionName, itemsCollectionName, parentIdx);
        const withoutSelected = selection.filter(item => item[`${prop}Description`] !== this.state.products[this.state.idx].inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx][`${prop}Description`]);

        this.state.products[this.state.idx].inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName].forEach(item => {
            if (withoutSelected[withoutSelected.length - 1]) {
                if (withoutSelected[withoutSelected.length - 1][`${prop}Description`] === item[`${prop}Description`]) {
                    item.selected = false;
                }
            }
        });
    }

    getSelection = (collectionName, itemsCollectionName, parentIdx) => {
        return this.state.products[this.state.idx].inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName].filter(item => item.selected);
    }

    //Product Update - Quantity
    handleQtyChange = (e) => {
        const updatedProduct = updateObject(this.state.products[this.state.idx], {
            quantity: Number(e.target.value)
        });

        this.setState({
            products: [
                ...this.state.products.slice(0, this.state.idx),
                updatedProduct,
                ...this.state.products.slice(this.state.idx + 1)
            ]
        })
    }

    // Button Events
    handleBackButton = (e) => {
        e.preventDefault();

        this.props.history.goBack();
    }

    previousProductSelectOrUpdate = (event) => {
        event.preventDefault();

        if (this.state.editMode && this.state.comboGroupDetails[this.state.idx].bIsComboChoiceGroup) {
            this.setState({
                editMode: false,
                imageError: false
            });
        } else {
            const idx = this.state.idx - 1;
            const comboGroupDetail = this.state.comboGroupDetails[idx];
            const editMode = !comboGroupDetail.bIsComboChoiceGroup;

            this.setState({
                idx: idx,
                editMode: editMode,
                imageError: false
            });
        }
        window.scrollTo(0, 0);
    }

    nextProductSelect = (event) => {
        event.preventDefault();

        const idx = this.state.idx + 1;
        const comboGroupDetail = this.state.comboGroupDetails[idx];
        const editMode = !comboGroupDetail.bIsComboChoiceGroup;

        this.setState({
            idx: idx,
            editMode: editMode,
            imageError: false
        });
        window.scrollTo(0, 0);
    }


    editProduct = () => {
        const cartProducts = clone(this.props.arrCart);

        this.state.products.forEach(product => {
            product.quantity = this.state.products[0].quantity;
        });

        this.state.products.forEach(product => {
            const index = cartProducts.findIndex(x => x.comboID === product.comboID && x.comboOrderID === product.comboOrderID && x.comboChoiceGroupID === product.comboChoiceGroupID);
            cartProducts[index] = product;
        });

        this.props.updateCartProducts(cartProducts);
        this.props.startCartUpdate();
        this.props.history.push('/cart');
        this.props.clearEditProduct();
    }

    addToCart = event => {
        event.preventDefault();

        var hasAlcohol = false;
        this.state.products.forEach(product => {
            hasAlcohol = hasAlcohol || product.bAlcohol;
            product.quantity = this.state.products[0].quantity;
        });

        this.props.clearSearch();

        if (this.validateProduct()) {

            this.props.history.push('/menu');

            if (!this.props.alcoholAcknowledged && hasAlcohol) {
                this.props.openModal({
                    type: MODAL_ALCOHOL,
                    data: {
                        products: this.state.products,
                        showSuggestions: true
                    }
                });
            }
            else {
                this.props.startAddToCart({
                    products: this.state.products,
                    showSuggestions: true, 
                    ga: this.props.ga, 
                    currencyCode: this.props.config.isoCurrencyCode
                });
            }
        }
    }

    handleItemClick = (categoryId, inventoryItemID, itemInAvailableWindow) => e => {
        e.preventDefault();

        if (itemInAvailableWindow) {
            if (!this.state.products.filter(x => x).find(x => x.inventoryItemID === inventoryItemID)) {
                var product = this.setProductDefaults(clone(this.props.data.inventoryItemMains.find(item => item.inventoryItemID === inventoryItemID)), this.state.combo.comboID, this.state.comboOrderID, this.state.comboGroupDetails[this.state.idx].comboChoiceGroupID);

                this.setState({
                    products: [
                        ...this.state.products.slice(0, this.state.idx),
                        product,
                        ...this.state.products.slice(this.state.idx + 1)
                    ],
                    editMode: true,
                    imageError: false
                })
            }
            else {
                this.setState({
                    editMode: true,
                    imageError: false
                })
            }
        }
        else {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.messages.mobile_ordering_unavailable_html,
                    btnText: window.resources.modal.confirm_text
                }
            });
        }
    }

    //Display
    getComboPrice() {
        let total = 0;

        for (var i = 0; i < this.state.idx + 1; i++) {
            var selectedProduct = this.state.products[i];
            let totalCost = 0;
            if (selectedProduct && (i < this.state.idx || (i === this.state.idx && this.state.editMode))) {
                selectedProduct.inventoryMainOptionChoice.choices.forEach(choice => {
                    choice.inventoryChoices.forEach(innerChoice => {
                        if (innerChoice.selected) {
                            totalCost += Number(innerChoice.choiceCost);
                        }
                    });
                });

                selectedProduct.inventoryMainOptionChoice.options.forEach(option => {
                    option.inventoryOptions.forEach(innerOption => {
                        if (innerOption.selected) {
                            totalCost += Number(innerOption.optionCost);
                        }
                    });
                });

                const size = selectedProduct.inventoryItemSubs.find(x => x.selected);
                let sizeCost = 0;
                if (size) {
                    sizeCost = Number(size.comboCost);
                }

                const totalPrice = totalCost + sizeCost;

                total += (totalPrice * this.state.products[0].quantity);
            }
        }

        if (total === 0.00) {
            return total.toFixed(2);
        }
        else {
            return total.toFixed(2);
        }
    }

    //Validation
    validateProduct = () => {
        const numberOfRequeredSelections = [];
        const numberOfCheckedSelections = [];
        const checks = [
            ...this.state.products[this.state.idx].inventoryMainOptionChoice.options,
            ...this.state.products[this.state.idx].inventoryMainOptionChoice.choices
        ];
        const checkedSelections = [
            ...this.getSelectedItems(this.state.products[this.state.idx].inventoryMainOptionChoice.options, 'option'),
            ...this.getSelectedItems(this.state.products[this.state.idx].inventoryMainOptionChoice.choices, 'choice')
        ];

        checks.forEach(item => {
            //if ( item.optionSelection > 0 ) {
            //	numberOfRequeredSelections.push(true);
            //}

            if (item.choiceSelection > 0) {
                numberOfRequeredSelections.push(true);
            }
        });

        //size
        numberOfRequeredSelections.push(true);

        checkedSelections.forEach(item => {
            //if ( item.inventoryOptions ) {
            //	if ( item.optionSelection <= 0 ) return;

            //	if ( item.inventoryOptions.length === Number(item.optionSelection)) {
            //		numberOfCheckedSelections.push(true);
            //	}
            //}

            if (item.inventoryChoices) {
                if (item.choiceSelection <= 0) return;

                if (item.inventoryChoices.length === Number(item.choiceSelection)) {
                    numberOfCheckedSelections.push(true);
                }
            }
        });

        //size
        if (this.state.products[this.state.idx].inventoryItemSubs.length === 0 || this.state.products[this.state.idx].inventoryItemSubs.filter(x => x.selected).length === 1) {
            numberOfCheckedSelections.push(true);
        }

        return numberOfRequeredSelections.length === numberOfCheckedSelections.length;
    }

    getSelectedItems = (items, prop) => {
        const capFirstLetter = `${prop[0].toUpperCase()}${prop.slice(1)}`;

        return items.map(item => ({
            ...pickAll([
                `${prop}Selection`,
                `${prop}GroupName`,
            ], item),
            [`inventory${capFirstLetter}s`]: item[`inventory${capFirstLetter}s`].filter(innerItem => innerItem.selected)
        })).filter(selection => !isEmpty(selection[`inventory${capFirstLetter}s`]));
    }

    //Render
    renderProductHeader = () => {
        const imgUrl = encodeURI(this.state.products[this.state.idx].imageV2.product_16_9 || this.state.products[this.state.idx].imageV2.product_1_1 || this.state.products[this.state.idx].inventoryItemImageName);

        return (
            <div className="shell">
                <div className="combo__item">
                    <div className={(!imgUrl || (this.state.imageError)) ? 'combo__content-full-width' : 'combo__content'}>
                        <h6 className="combo__title combo__title--main">{this.state.products[this.state.idx].inventoryItemName} {this.props.showCalories && this.state.products[this.state.idx].inventoryItemSubs.length === 1 && this.state.products[this.state.idx].inventoryItemSubs[0].calorieDisplay && '(' + this.state.products[this.state.idx].inventoryItemSubs[0].calorieDisplay + ' calories)'}</h6>

                        <div className="combo__entry">
                            <p>{this.state.products[this.state.idx].inventoryItemDescription}</p>
                        </div>

                        <div className="combo__meta">
                            <p className="combo__price">{this.state.products[this.state.idx].inventoryItemSubs.length > 1 ? this.state.products[this.state.idx].inventoryItemSubs[0].comboCost.toFixed(2) + ' - ' + this.state.products[this.state.idx].inventoryItemSubs[this.state.products[this.state.idx].inventoryItemSubs.length - 1].comboCost.toFixed(2) : this.state.products[this.state.idx].inventoryItemSubs[0].comboCost === 0.00 ? window.resources.combo.zero_text : this.state.products[this.state.idx].inventoryItemSubs[0].comboCost.toFixed(2)}</p>
                        </div>
                    </div>
                    {!(!imgUrl || (this.state.imageError)) && <figure
                        className="combo__image"
                        style={{
                            backgroundImage: `url('${imgUrl}')`
                        }}>
                        <img src={imgUrl} style={{ display: 'none' }} alt="productImage" onError={(e) => {
                            if (imgUrl) {
                                this.setState(prevState => {
                                    return {
                                        imageError: true
                                    };
                                });
                            }
                        }}
                        />
                    </figure>}
                </div>
            </div>
        );
    }

    isCombo = (inventoryItemID) => {
        return true;
    }

    getComboChoiceGroupProducts = (comboChoiceGroupID) => {
        let comboChoiceGroupDetails = [...this.props.data.comboEngine.comboChoiceGroups.find(x => x.comboChoiceGroupID === comboChoiceGroupID).comboChoiceGroupDetails];
        comboChoiceGroupDetails.sort((a, b) => Number(a.inventoryItemDisplayOrder) > Number(b.inventoryItemDisplayOrder));
        return comboChoiceGroupDetails.map(c => this.props.data.inventoryItemMains.find(item => item.inventoryItemID === c.inventoryItemID) || c.inventoryItemMain);
    }

    returnToHome = () => {
        this.props.history.push(`/${this.props.tableNumber}`);
    }

    render() {

        return (
            <div className="combo">
                <Header />
                <div className="combo__breadcrumbs">
                    <div className="shell shell--no-padding">
                        <div className="steps">
                            {this.state.comboGroupDetails.map((c, i) =>
                                <div style={{ width: (100 / this.state.comboGroupDetails.length).toFixed(2) + '%' }} className={`step ${i <= this.state.idx ? 'active' : ''} ${i === this.state.idx ? 'current' : ''}`}>
                                <div className="step__inner">
                                    <div className="step__number">{i < this.state.idx ? <i class="fas fa-check"></i> : i + 1}</div>
                                    <div className="step__entry">
                                        <p>{c.inventoryItemName}</p>
                                    </div>
                                    <div className="step_underline"></div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>

                <div className="combo__body">
                    {this.state.editMode && this.renderProductHeader()}

                    <div className="combo__holder">
                        <div className="shell shell--no-padding">

                            {this.state.editMode && <div>
                                <ProductSizes product={this.state.products[this.state.idx]} handleSizeChange={this.handleSizeChange} mode={this.props.mode} sortProductSizesByCostDescending={this.props.sortProductSizesByCostDescending} />

                                <ProductChoices product={this.state.products[this.state.idx]} handleCheckboxChange={this.handleCheckboxChange} mode={this.props.mode} />

                                <ProductOptions product={this.state.products[this.state.idx]} handleCheckboxChange={this.handleCheckboxChange} mode={this.props.mode} />
                                
                                {this.state.idx === 0 && this.props.mode === MODE_ORDER && <ProductQuantity product={this.state.products[this.state.idx]} handleQtyChange={this.handleQtyChange} mode={this.props.mode}/>}
                            </div>}

                            {!this.state.editMode &&
                                <div className="shell">
                                    <MenuItem
                                        onItemClick={this.handleItemClick}
                                    category={{ inventoryTitleDescription: 'SELECT A ' + this.state.comboGroupDetails[this.state.idx].inventoryItemName.toUpperCase().slice(0, -1), detailsDescription: '', inventorySubTitles: [] }}
                                        selectedProducts={[]}
                                        products={this.getComboChoiceGroupProducts(this.state.comboGroupDetails[this.state.idx].comboChoiceGroupID)}
                                        showCalories={this.props.showCalories}
                                        isCombo={this.isCombo} />
                                </div>}

                            <div className="combo__section combo__section--alt">
                                {this.props.match.params.comboOrderID && this.state.comboGroupDetails.length === this.state.idx + 1 && (
                                    <button
                                        onClick={this.editProduct}
                                        disabled={!this.state.editMode || !this.validateProduct()}
                                        className={classnames({
                                            'btn btn--block btn--flex-between': true,
                                            'btn--disabled': !this.state.editMode || !this.validateProduct()
                                        })}>
                                        <span>{this.getComboPrice()}</span>

                                        <span>{window.resources.combo.edit_text}</span>
                                    </button>
                                )}
                                {!this.props.match.params.comboOrderID && this.state.comboGroupDetails.length === this.state.idx + 1 && this.props.mode === MODE_ORDER && (
                                    <button
                                        onClick={this.addToCart}
                                        disabled={!this.state.editMode || !this.validateProduct()}
                                        className={classnames({
                                            'btn btn--block btn--flex-between': true,
                                            'btn--disabled': !this.state.editMode || !this.validateProduct()
                                        })}>
                                        <span>{this.getComboPrice()}</span>

                                        <span>{window.resources.combo.add_to_basket_text}</span>
                                    </button>
                                )}
                                {!this.props.match.params.comboOrderID && this.state.comboGroupDetails.length === this.state.idx + 1 && this.props.mode !== MODE_ORDER && (
                                    <button
                                        onClick={this.returnToHome}
                                        disabled={false}
                                        className={classnames({
                                            'btn btn--block btn--flex-between': true,
                                            'btn--disabled': false
                                        })}>
                                        <span>{this.getComboPrice()}</span>

                                        <span>{window.resources.combo.return_to_home_text}</span>
                                    </button>
                                )}
                                {this.state.comboGroupDetails.length > this.state.idx + 1 && <button
                                    onClick={this.nextProductSelect}
                                    disabled={(!this.state.editMode && this.props.mode === MODE_ORDER) || (this.state.editMode && !this.validateProduct() && this.props.mode === MODE_ORDER)}
                                    className={classnames({
                                        'btn btn--block btn--flex-between': true,
                                        'btn--disabled': (!this.state.editMode && this.props.mode === MODE_ORDER) || (this.state.editMode && !this.validateProduct() && this.props.mode === MODE_ORDER)
                                    })}>
                                    <span>{this.getComboPrice()}</span>
                                    <span>{window.resources.combo.next_text}: {this.state.comboGroupDetails[this.state.idx + 1].inventoryItemName}</span>
                                </button>}
                                {this.state.idx > 0 && !this.state.editMode && <button
                                    onClick={this.previousProductSelectOrUpdate}
                                    className={classnames({
                                        'btn btn--secondary-2 btn--block btn--flex-between': true
                                    })}>
                                    <span></span>
                                    <span>{window.resources.combo.update_text}: {this.state.comboGroupDetails[this.state.idx - 1].inventoryItemName}</span>
                                </button>}

                                {this.state.idx > 0 && this.state.editMode && <button
                                    onClick={this.previousProductSelectOrUpdate}
                                    className={classnames({
                                        'btn btn--secondary-2 btn--block btn--flex-between': true
                                    })}>
                                    <span></span>
                                    <span>Update: {this.state.comboGroupDetails[this.state.idx].inventoryItemName}</span>
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    connect(
        (state) => ({
            total: state.cart.data.totalCost,
            taxRate: state.cart.modifiers.taxRate,
            arrCart: state.cart.data.arrCart,
            editableProduct: state.cart.editableProduct,
            cartItems: state.cart.data.arrCart,
            cartPricingData: state.cart.cartPricingData,
            showCalories: state.app.config && state.app.config.bShowCalorieDisplay,
            allProducts: state.app.data && state.app.data.inventoryItemMains,
            alcoholAcknowledged: state.cart.data && state.cart.data.alcoholAcknowledged,
            mode: state.cart.mode,
            sortProductSizesByCostDescending: features.sortProductSizesByCostDescending(state),
            tableNumber: state.checkout.tableNumber,
            ga: state.app.ga, 
            config: state.app.config
        }),
        {
            startAddToCart,
            openModal,
            updateCartTotal,
            updateModifiers,
            updateCartProducts,
            startCartUpdate,
            clearEditProduct,
            clearSearch
        }
    ),
    withDataValidation
)(Combo);