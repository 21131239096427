import React, { useState } from 'react';
import IconSvg from './icon-svg';
import config from 'config';

const StoreAssetSvg = (props) => {
    const srcs = window.styleWaypointIDs.map(styleWaypointID => config.REACT_APP_STORE_ASSETS_URL + styleWaypointID + (props.path.startsWith('/') ? '' : '/') + props.path);

    const [index, setIndex] = useState(srcs.length - 1);

    return <IconSvg src={srcs[index]} onError={() => {
        if (index - 1 >= 0) {
            setIndex(index - 1);
        }
    }} {...props} />;
}

export default StoreAssetSvg;