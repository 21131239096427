/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * @ The internal dependecies.
 */
import { MODAL_ALCOHOL } from 'lib/constants';
import { openModal, closeModal } from 'store/state/ui/actions';
import { getUpsaleProducts } from 'store/state/cart/selectors';
import QtyField from 'components/common/qty-field';
import { updateObject } from '../../lib/utility';
import { startAddToCart } from '../../store/state/cart/actions';
import { peopleAlsoAddedView, peopleAlsoAddedAddition, peopleAlsoAddedCancel } from '../../store/state/customer/actions';
import { localizeNumber } from '../../assets/resources/resources-manager';
import * as Analytics from '../../lib/analytics-ga4';

/**
 * Class for Modal Suggestions.
 *
 * @class ModalSuggestions (name)
 */
class ModalSuggestions extends Component {
    state = {
        upsaleProducts: [],
        products: {},
        formIsValid: false
	}

    handleQtyChange = ({ index, id }) => (e) => {
        const productIdentifier = `${id}`;

        const updatedProduct = updateObject(this.state.products[productIdentifier], {
            quantity: Number(e.target.value)
        });

        const updatedproducts = updateObject(this.state.products, {
            [productIdentifier]: updatedProduct
        });

        let formIsValid = false;
        for (let key in updatedproducts) {
            const productQuantity = updatedproducts[key];
            if (productQuantity.active && productQuantity.quantity > 0) {
                formIsValid = true;
                break;
            }
        }

        this.setState({
            products: updatedproducts,
            formIsValid: formIsValid
        });
    }

    handleActiveChange = ({ index, id }) => (e) => {
        const productIdentifier = `${id}`;

        const updatedProduct = updateObject(this.state.products[productIdentifier], {
            active: !this.state.products[productIdentifier].active
        });

        const updatedproducts = updateObject(this.state.products, {
            [productIdentifier]: updatedProduct
        });

        let formIsValid = false;
        for (let key in updatedproducts) {
            const productQuantity = updatedproducts[key];
            if (productQuantity.active && productQuantity.quantity > 0) {
                formIsValid = true;
                break;
            }
        }

        this.setState({
            products: updatedproducts,
            formIsValid: formIsValid
        });
    }

	componentWillMount() {
        this.props.allProducts.forEach((product, i) => {
			this.setState(({ products }) => ({
				products: {
					...products,
                    [`${product.inventoryItemID}`]: {
                        inventoryItemID: product.inventoryItemID,
                        quantity: 1,
                        active: false
                    }
				}
			}));
        });

        this.props.products.forEach((product, i) => {
            this.props.peopleAlsoAddedView(product.inventoryItemID);
        });

        this.setState({ upsaleProducts: [...this.props.products] });
        if (this.props.ga) Analytics.logUpsellOrAddonProductList('upsell products', this.props.products)
    }

    noThanksHandler = (event) => {
        this.props.products.forEach((product, i) => {
            this.props.peopleAlsoAddedCancel(product.inventoryItemID);
        });

        this.props.closeModal();
    }

    addToOrderHandler = (event) => {
        const productsToAdd = []; 
        const alcoholicProducts = []; 
        for (let key in this.state.products) {
            const productQuantity = this.state.products[key];
            if (productQuantity.active) {
                const product = this.state.upsaleProducts.find(p => p.inventoryItemID === productQuantity.inventoryItemID);

                const productToAdd = updateObject(product,
                    {
                        quantity: productQuantity.quantity
                    });

                //Select the first size
                if (productToAdd.inventoryItemSubs.length > 0) {
                    productToAdd.inventoryItemSubs[0].selected = true;
                }

                if (productQuantity.quantity > 0) {
                    this.props.peopleAlsoAddedAddition(productQuantity.inventoryItemID);
                }

                if (!this.props.alcoholAcknowledged && productToAdd.bAlcohol) {
                    alcoholicProducts.push(productToAdd);
                }
                else {
                    productsToAdd.push(productToAdd);
                }
            }
            else {
                const product = this.props.products.find(p => p.inventoryItemID === productQuantity.inventoryItemID);
                if (product) {
                    this.props.peopleAlsoAddedCancel(productQuantity.inventoryItemID);
                }
            }
        }

        this.props.closeModal();

        if (productsToAdd.length > 0) {
            this.props.startAddToCart({
                products: productsToAdd,
                showSuggestions: false, 
                type: 'upsell products', 
                ga: this.props.ga, 
                currencyCode: this.props.config.isoCurrencyCode
            });
        }

        if (alcoholicProducts.length > 0) {
            this.props.openModal({
                type: MODAL_ALCOHOL,
                data: {
                    products: alcoholicProducts,
                    showSuggestions: false
                }
            });
        }
    }

	render() {
        const { upsaleProducts } = this.state;
        if (upsaleProducts.length === 0) {
            this.props.closeModal();
        }

		return (
            <div className="modal-frame modal-frame--alt">
				<div className="modal-frame__head">
                    <h6 className="modal-frame__title">{window.resources.suggestions.title_text}</h6>
				</div>

                <div className="modal-frame__body modal-frame__body--alt">
					<div className="boxes">
                        {upsaleProducts.map((product, i) => (
							<div
								key={product.inventoryItemID + product.inventoryItemName + i}
								className="box">
                                <input
                                    onChange={this.handleActiveChange({
                                        index: i,
                                        id: product.inventoryItemID
                                    })}
                                    checked={this.state.products[`${product.inventoryItemID}`].active}
									className="box__field"
									type="checkbox"
									id={`box-${i}`}
								/>

								<label className="box__inner" htmlFor={`box-${i}`}>
									<div
										className="box__image"
										style={{
                                            backgroundImage: `url('${encodeURI(product.imageV2.product_1_1 || product.inventoryItemImageName)}')`
										}}>
										<div className="box__qty">
											<QtyField
												modifier="small"
												variant="primary"
												withanimation="true"
												min={1}
												onChange={this.handleQtyChange({
													index: i,
													id: product.inventoryItemID
												})}
												value={this.state.products[`${product.inventoryItemID}`].quantity}
											/>
										</div>
									</div>

									<div className="box__content">
										<h6 className="box__title">{product.inventoryItemName}</h6>

										<div className="box__meta">
											<h6 className="box__price">{localizeNumber(product.inventoryItemSubs[0].cost)}</h6>

											<span className="box__icon"></span>
										</div>
									</div>
								</label>
							</div>
						))}
					</div>
				</div>

				<div className="modal-frame__foot">
					<div className="modal-frame__actions">
                        <button onClick={this.addToOrderHandler}
                            disabled={!this.state.formIsValid}
                            className="btn btn--block">{window.resources.suggestions.confirm_text}</button>
                        <button
                            onClick={this.noThanksHandler}
                            className="btn btn--secondary btn--block">{window.resources.suggestions.cancel_text}</button>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
    (state) => ({
        allProducts: state.app.data.inventoryItemMains,
        products: getUpsaleProducts([], state.ui.modal.data ? state.ui.modal.data.upsaleItems : [], state.app.data.inventoryItemMains),
        alcoholAcknowledged: state.cart.data && state.cart.data.alcoholAcknowledged, 
        ga: state.app.ga, 
        config: state.app.config
	}),
    {
        openModal,
        closeModal,
        startAddToCart,
        peopleAlsoAddedView,
        peopleAlsoAddedAddition,
        peopleAlsoAddedCancel
	}
)(ModalSuggestions);
