import config from '../../config';

export const setupLanguages = (waypointIDs, onLoad, onError) => {
    var supportedLanguagesUrl = config.REACT_APP_LANGUAGES_URL + 'supported-languages.json';
    var defaultLanguagesUrl = config.REACT_APP_LANGUAGES_URL + 'languages.json';

    var fetchDefaultLanguagesPromise = Promise.all([
        fetch(supportedLanguagesUrl).then(response => response.json()).then(json => window.supportedLanguages = json),
        fetch(defaultLanguagesUrl).then(response => response.json()).then(json => window.languages = json)
    ]).catch(error => {
        console.error(`Cannot load ${supportedLanguagesUrl} or ${defaultLanguagesUrl}`);
    });

    var fetchOverridePromises = waypointIDs.map(waypointID => fetch(config.REACT_APP_STORE_LANGUAGES_URL + waypointID + '/languages.json').then(response => response.json()));

    Promise.allSettled([fetchDefaultLanguagesPromise, ...fetchOverridePromises]).then(results => {
        if (results.some(result => result.status === 'fulfilled')) {
            for (var i = results.length - 1; i > 0; i--) {
                if (results[i].status === 'fulfilled') {
                    var data = results[i].value;
                    window.languages = data;
                    break;
                }
            }
            if (onLoad) onLoad();
        }
        else {
            if (onError) onError();
        }
    });
}

export const setupLanguagesMultiConcept = (multiConceptIDs, onLoad, onError) => {
    var supportedLanguagesUrl = config.REACT_APP_LANGUAGES_URL + 'supported-languages.json';
    var defaultLanguagesUrl = config.REACT_APP_LANGUAGES_URL + 'languages.json';

    var fetchDefaultLanguagesPromise = Promise.all([
        fetch(supportedLanguagesUrl).then(response => response.json()).then(json => window.supportedLanguages = json),
        fetch(defaultLanguagesUrl).then(response => response.json()).then(json => window.languages = json)
    ]).catch(error => {
        console.error(`Cannot load ${supportedLanguagesUrl} or ${defaultLanguagesUrl}`);
    });

    var fetchOverridePromises = multiConceptIDs.map(multiConceptID => fetch(config.REACT_APP_MULTI_CONCEPT_LANGUAGES_URL + multiConceptID + '/languages.json').then(response => response.json()));

    Promise.allSettled([fetchDefaultLanguagesPromise, ...fetchOverridePromises]).then(results => {
        if (results.some(result => result.status === 'fulfilled')) {
            for (var i = results.length - 1; i > 0; i--) {
                if (results[i].status === 'fulfilled') {
                    var data = results[i].value;
                    window.languages = data;
                    break;
                }
            }
            if (onLoad) onLoad();
        }
        else {
            if (onError) onError();
        }
    });
}