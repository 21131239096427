/**
 * @ The external dependencies.
 */
 import React from 'react';
 import { useHistory } from 'react-router-dom';

 import emptyBox from '../../assets/images/empty_box.png';

 const InvalidStore = () => {
    const history = useHistory();

    return (
    <div className="product-single__body">
        <div className="product-single__holder">
            <div className='shell shell--no-padding'>
                <div className='invalid__item'>
                    <div className='product-single__section'><img style={{width:'165px'}} src={emptyBox} alt="Invalid Item" /></div>
                    <div className='product-single__section product-single__section--alt invalid__item_msg'>{window.resources.product.invalid_item_text}</div>
                </div>
                <div className='product-single__section product-single__section--alt'>
                    <button
                        onClick={() => history.push('/menu')}
                        className='btn btn--block'>
                            <span>{window.resources.product.return_menu}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
 )}

 export default InvalidStore;