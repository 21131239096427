/**
 * @ The external dependencies.
 */
import {
	all,
	call,
	put,
	takeLatest,
	select
} from 'redux-saga/effects';


/**
 * @ The internal dependencies.
 */
import config from 'config';
import { getSelectedItems } from 'store/state/cart/selectors';
import { reciveCartData } from 'store/state/cart/actions';
import { updateTick, receiveTableTopData, requestTableTopConfig, receiveTableTopConfig, requestTableTopConfigBeforeOrder, receiveTableTopConfigBeforeOrder, requestTableTopData, requestMultiConceptData, receiveMultiConceptData } from './actions';
import api from 'lib/api';
import {
	startRequest,
	endRequest,
	endRequestWithError
} from 'store/state/requests/actions';
import { retryCartUpdate } from '../cart/selectors';
import { getTabId, hasTabOpenAndAuthorized } from '../tab/selectors';


import makeRequest from 'lib/helpers/api-request';

/**
 * Start a network request to fetch config.
 *
 * @param  {Object}    action
 * @return {Generator}
 */
export function* requestTableTopConfigWorker(action) {
    try {
        yield put(startRequest(action.type));

        const tableConfig = yield call([api, 'getTableTopConfig']);

        var domain = tableConfig.SubdomainOverride;
        if (domain && window.location.hostname !== 'localhost' && domain !== window.location.hostname) {
            //if the window domain does not equal the config SubdomainOverride, redirect to SubdomainOverride.
            window.location = `${window.location.protocol}//${domain}${window.location.pathname}${window.location.search}`;
        }

        yield put(receiveTableTopConfig(tableConfig));


        const performOrderCalc = !action.payload || action.payload.performOrderCalc === null || action.payload.performOrderCalc === true;
        
        if (performOrderCalc) {
            const asyncOrderCalc = action.payload && action.payload.asyncOrderCalc === true;

            //For the home screen stop the loading spinner and let the cart calc happen in the background.
            if (asyncOrderCalc) {
                yield put(endRequest(action.type));
            }

            //Only want to send tabId if authorized and has tabId.
            let tabId = (yield select(hasTabOpenAndAuthorized)) ? (yield select(getTabId)) : '';

            let calculationFailed = false;
            const retries = config.RETRIES;
            const delayBetweenRetries = config.RETRY_DELAY;

            //retries
            for (let i = 0; i < retries; i++) {

                calculationFailed = false;

                let { cart, loyalty } = yield select();

                let cartDetails = {
                    ...cart.data,
                    loyaltyIdentifier: loyalty.accountNumber
                };

                if (tabId) {
                    cartDetails = {
                        ...cartDetails,
                        openCheckTicketID: tabId
                    };
                }

                const cartPricintData = yield call([api, 'postToCart'], {
                    ...cartDetails,
                    arrCart: cart.data.arrCart.map(product => ({
                        inventoryItemSubs: product.inventoryItemSubs.filter(x => x.selected).map(item => ({
                            inventoryItemSubID: item.inventoryItemSubID,
                            inventoryItemSubName: item.inventoryItemSubName,
                            selected: item.selected,
                            cost: item.cost
                        })),
                        inventoryItemID: product.inventoryItemID,
                        inventoryItemName: product.inventoryItemName,
                        quantity: product.quantity,
                        specialNotes: "",
                        inventoryOrder: product.inventoryOrder,
                        inventoryMainOptionChoice: {
                            options: getSelectedItems(product.inventoryMainOptionChoice.options, 'option'),
                            choices: getSelectedItems(product.inventoryMainOptionChoice.choices, 'choice'),
                        }
                    }))
                });

                yield put(reciveCartData(cartPricintData));

                if (!calculationFailed) {
                    calculationFailed = (yield select(retryCartUpdate));
                    if (calculationFailed) {
                        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                            console.log('Recalculating Order Cost, Retrying...')
                        }
                    }
                }

                if (calculationFailed && i < retries) {
                    yield delay(delayBetweenRetries);
                }
                else {
                    //exit retry loop
                    break;
                }
            }

            //Determines when home shown is shown.
            if (!asyncOrderCalc) {
                yield put(endRequest(action.type));
            }
        }
        else {
            yield put(endRequest(action.type));
        }    
    } catch (message) {
        yield put(endRequestWithError(action.type, message));
    }
}

export function* requestTableTopConfigBeforeOrderWorker(action) {
    try {
        yield put(startRequest(action.type));

        const tableData = yield call([api, 'getTableTopConfig']);

        yield put(receiveTableTopConfigBeforeOrder(tableData));

        yield put(endRequest(action.type));
    } catch (message) {
        yield put(endRequestWithError(action.type, message));
    }
}

/**
 * Start a network request to fetch all featured items.
 *
 * @param  {Object}    action
 * @return {Generator}
 */
export function* requestTableTopDataWorker(action) {
	try {
		yield put(startRequest(action.type));

		const tableData = yield call([api, 'getTableTopData']);

		yield put(receiveTableTopData(tableData));

		yield put(endRequest(action.type));
	} catch (message) {
		yield put(endRequestWithError(action.type, message));
	}
}

export function* requestMultiConceptDataWorker(action) {
    const payload = {
        multiConceptID: window.multiConceptID
    };

    yield call(makeRequest, {
        endpoint: 'getMultiConceptData',
        payload: payload,
        requestAction: action,
        receiveAction: receiveMultiConceptData
    });
}

/**
 * Allow UI to update independently of user performing an action.
 */
function delay(duration) {
    const promise = new Promise(resolve => {
        setTimeout(() => resolve(true), duration)
    })
    return promise
}

function* updateTickWorker() {
    while (false) {
        yield put(updateTick());
        yield call(delay, 1000);
    }
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(requestMultiConceptData, requestMultiConceptDataWorker),
        call(updateTickWorker),
        takeLatest(requestTableTopConfig, requestTableTopConfigWorker),
        takeLatest(requestTableTopConfigBeforeOrder, requestTableTopConfigBeforeOrderWorker),
        takeLatest(requestTableTopData, requestTableTopDataWorker)
	]);
}
