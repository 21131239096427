//Feature must be enabled and then can be switched on/off at a store level.
const features = {
    tipButtonFeatureEnabled: true,
    tippingPopFeatureEnabled: true,
    tabFeatureEnabled: true,
    payNowFeatureDisabled: true,
    employeeDiscountFeatureEnabled: true,
    surveyQuestionsFeatureEnabled: true,
    textReceiptFeatureEnabled: true,
    emailReceiptFeatureEnabled: true,
    autoReceiptFeatureEnabled: true,
    autoReceiptVKFeatureEnabled: true,
    multiConceptFeatureEnabled: true,
    retryCartUpdateForOfflineItemsEnabled: true,
    searchFeatureEnabled: true,
    threeDSecureEnabled: true,
    showReceiptForZeroFeatureEnabled: true,
    alcoholMealLimitEnabled: true,
    sortProductSizesByCostDescendingEnabled: true,
    loadedValueAccountEnabled: true,
    orderingModeEnabled: true,
    payTabNowModeEnabled: true,
    browseModeEnabled: true,
    CYOAEnabled: true,
    takeAwayNameRequired: true,
    dineInNameRequired: true,
    dineInNameOptional: true,
    loyaltyFeatureEnabled: true,
    twoColumnCategoriesFeatureEnabled: true,
    tipForAlcoholOnlyFeatureEnabled: true,
    hideZeroDollars: true,
    tabSplitPayEnabled:  true, 
    tabSplitEvenEnabled: true, 
    tabSplitCustomEnabled: true, 
    tabSplitMinTab: 5, 
    tabSplitMaxSplits: 5,
    orderOnlyNoPayment: true, 
    donationEnabled: true, 
    sommelierEnabled: true,
    tableTentOverride: true
};

export const tipButtonFeatureEnabled = (state) => {
    //Tipping button only enabled if non virtual kiosk
    return features.tipButtonFeatureEnabled && state.app.config && state.app.config.bPOSTippingEnabled && state.cart.data;// && !state.cart.data.isVirtualKiosk;
}

export const tippingPopFeatureEnabled = (state) => {
    //Tipping pop only enabled if non virtual kiosk
    return features.tippingPopFeatureEnabled && state.app.config && state.app.config.tippingPopEnabled && state.cart.data;// && !state.cart.data.isVirtualKiosk;
}

export const tabFeatureEnabled = (state) => {
    //Tab only enabled if non virtual kiosk
    return features.tabFeatureEnabled && state.app.config && state.app.config.openTabEnabled && state.cart.data && !state.cart.data.isVirtualKiosk/*&& window.openTabBypass*/;
}

export const payNowFeatureDisabled = (state) => {
    return features.payNowFeatureDisabled && state.app.config && state.app.config.payNowDisabled;
}

export const employeeDiscountFeatureEnabled = (state) => {
    return features.employeeDiscountFeatureEnabled && state.app.config && state.app.config.employeeDiscountEnabled;
}

export const surveyQuestionsFeatureEnabled = (state) => {
    return features.surveyQuestionsFeatureEnabled && state.app.config && state.app.config.starRatingsEnabled;
}

export const textReceiptFeatureEnabled = (state) => {
    return features.textReceiptFeatureEnabled && state.app.config && state.app.config.textReceiptEnabled;
}

export const emailReceiptFeatureEnabled = (state) => {
    return features.emailReceiptFeatureEnabled && state.app.config && state.app.config.emailReceiptEnabled;
}

export const autoReceiptFeatureEnabled = (state) => {
    return features.autoReceiptFeatureEnabled && state.app.config && state.app.config.autoReceiptEnabled;
}

export const autoReceiptVKFeatureEnabled = (state) => {
    return features.autoReceiptVKFeatureEnabled && state.app.config && state.app.config.OATAutoReceiptForVKEnabled;
}

export const multiConceptFeatureEnabled = (state) => {
    return features.multiConceptFeatureEnabled && state.app.config && state.app.config.MultiConceptID && state.app.config.MultiConceptID > 0 && state.app.config.MultiConceptEnabled;
}

export const retryCartUpdateForOfflineItemsEnabled = () => {
    return features.retryCartUpdateForOfflineItemsEnabled;
}

export const searchFeatureEnabled = (state) => {
    return features.searchFeatureEnabled && state.app.config && state.app.config.OATItemSearchEnabled;
}

export const threeDSecureEnabled = (state) => {
    return features.threeDSecureEnabled && state.app.config && ((state.app.config.hasOwnProperty('Is3DSecureEnabled') && state.app.config.Is3DSecureEnabled) || (!state.app.config.hasOwnProperty('Is3DSecureEnabled') && state.app.config.GMTOffset > -4));
}

export const showReceiptForZeroFeatureEnabled = (state) => {
    return features.showReceiptForZeroFeatureEnabled && state.app.config && state.app.config.showReceiptForZero;
}

export const alcoholMealLimitEnabled = (state) => {
    return features.alcoholMealLimitEnabled && state.app.config && state.app.config.alcoholMealLimitEnabled;
}

export const allowedAlcoholItemsPerMealItem = (state) => {
    return features.alcoholMealLimitEnabled && state.app.config && state.app.config.alcoholMealLimitEnabled ? state.app.config.allowedAlcoholItemsPerMealItem : false;
}

export const sortProductSizesByCostDescending = (state) => {
    return features.sortProductSizesByCostDescendingEnabled && state.app.config && state.app.config.sortProductSizesByCostDescending
}

export const loadedValueAccountEnabled = (state) => {
    return features.loadedValueAccountEnabled && state.app.config && state.app.config.loadedValueAccountEnabled
}

export const orderingModeEnabled = (state) => {
    return features.orderingModeEnabled && state.app.config && state.app.config.orderingModeEnabled;
}

export const payTabNowModeEnabled = (state) => {
    return features.payTabNowModeEnabled && state.app.config && state.app.config.payTabNowModeEnabled;
}

export const browseModeEnabled = (state) => {
    return features.browseModeEnabled && state.app.config && state.app.config.browseModeEnabled;
}

export const CYOAEnabled = (state) => {
    return features.CYOAEnabled && state.app.config && state.app.config.takeAwayEnabled
}

export const takeAwayNameRequired = (state) => {
    return features.takeAwayNameRequired && state.app.config && state.app.config.takeAwayNameRequired
}

export const dineInNameRequired = (state) => {
    return features.dineInNameRequired && state.app.config && state.app.config.dineInNameRequired;
}

export const dineInNameOptional = (state) => {
    return features.dineInNameOptional && state.app.config && state.app.config.dineInNameOptional;
}

export const loyaltyFeatureEnabled = (state) => {
    return features.loyaltyFeatureEnabled && state.app.config && state.app.config.redRoosterLoyaltyEnabled;
}

export const twoColumnCategoriesFeatureEnabled = (state) => {
    return features.twoColumnCategoriesFeatureEnabled && state.app.config && state.app.config.twoColumnCategoriesEnabled;
}

export const tipForAlcoholOnlyFeatureEnabled = (state) => {
    return features.tipForAlcoholOnlyFeatureEnabled && state.app.config && state.app.config.OATTipForAlcoholOnly;
}

export const hideZeroDollars = (state) => {
    return features.hideZeroDollars && state.app.config && state.app.config.hideZeroDollar;
}

export const tabSplitPayEnabled = (state) => {
    return features.tabSplitPayEnabled && state.app.config && state.app.config.OATSplitTabEnabled;
}
export const tabSplitEvenEnabled = (state) => {
    return features.tabSplitPayEnabled && features.tabSplitEvenEnabled && 
            state.app.config && state.app.config.OATSplitTabEnabled && 
            state.app.config.OATSplitTabEvenEnabled;
}
export const tabSplitCustomEnabled = (state) => {
    return features.tabSplitPayEnabled && features.tabSplitCustomEnabled && 
            state.app.config && state.app.config.OATSplitTabEnabled && 
            state.app.config.OATSplitTabCustomEnabled;
}
export const tabSplitMinTab = (state) => {
    return features.tabSplitPayEnabled && features.tabSplitMinTab && 
            state.app.config && state.app.config.OATSplitTabEnabled && 
            state.app.config.OATSplitTabMinTab;
}
export const tabSplitMaxSplits = (state) => {
    return features.tabSplitPayEnabled && features.tabSplitMaxSplits && 
            state.app.config && state.app.config.OATSplitTabEnabled && 
            state.app.config.OATSplitTabMaxSplits;
}
export const orderOnlyNoPayment = (state) => {
    //Tipping button only enabled if non virtual kiosk
    return features.orderOnlyNoPayment && state.app.config && state.app.config.OATOrderOnlyNoPayment;
}
export const donationEnabled = (state) => {
    return features.donationEnabled && state.app.config && state.app.config.OATDonationEnabled;
}
export const donationMerchId = (state) => {
    return features.donationEnabled && state.app.config && state.app.config.OATDonationMerchId ?
    state.app.config.OATDonationMerchId : null;
}
export const donationPenniesToken = (state) => {
    return features.donationEnabled && state.app.config && state.app.config.OATDonationPenniesToken ?
    state.app.config.OATDonationPenniesToken : null;
} 
export const sommelierEnabled = (state) => {
    return features.sommelierEnabled && state.app.config && state.app.config.OATSommelierEnabled;
}

export const tableTentOverride = (state) => {
    return features.tableTentOverride && state.app.config && state.app.config.OATTableTentOverride;
}
