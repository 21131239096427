/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { getProductByInventoryItemID, getComboGroupByInventoryItemID } from 'store/state/cart/selectors'

/**
 * Class for modal combo.
 *
 * @class ModalCombo (name)
 */
class ModalCombo extends Component {
    state = {
        imageError: false
    }

    handleCancelButton = (e) => {
        e.preventDefault();
        this.props.closeModal();
        this.props.history.push(`/menu/${this.props.inventoryItemID}`);
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.props.closeModal();
        this.props.history.push(`/menu/combo/${this.props.comboGroup.comboID}`);
    }

    replaceVariables(html) {
        return html.replace("className", "class")
            .replace("{difference}", (this.props.comboGroup.comboBasePrice - Number(this.props.product.inventoryItemSubs[0].cost)).toFixed(2))
            .replace("{items}", this.replaceLast(this.props.comboGroup.comboGroupDetails.filter(x => x.bIsComboChoiceGroup === true).map(x => `${window.resources.combo_modal.items_a} ${x.inventoryItemName.toLowerCase()}`).join(', '), ',', ` ${window.resources.combo_modal.items_and}`))
            .replace("{currencySymbol}", this.props.currencySymbol);
    }

    replaceLast(x, y, z) {
        var a = x.split("");
        var length = y.length;
        if (x.lastIndexOf(y) !== -1) {
            for (var i = x.lastIndexOf(y); i < x.lastIndexOf(y) + length; i++) {
                if (i === x.lastIndexOf(y)) {
                    a[i] = z;
                }
                else {
                    delete a[i];
                }
            }
        }

        return a.join("");
    }

    render() {
        const imgUrl = encodeURI(this.props.comboGroup.imageV2.combo_or_choiceGroup_mainImage);
        //const imgUrl = encodeURI(this.props.product.imageV2.product_16_9 || this.props.product.imageV2.product_1_1 || this.props.product.inventoryItemImageName);

		return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h5 className="modal-frame__title">{this.replaceVariables(window.resources.combo_modal.label_text)}</h5>
                        </div>

                        <div className="modal-frame__card-body editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.combo_modal.html) }}>

                        </div>
                        {!(!imgUrl || (this.state.imageError)) && <figure
                            className="modal-frame__image"
                            style={{
                                backgroundImage: `url('${imgUrl}')`
                            }}>
                            <img src={imgUrl} style={{ display: 'none' }} alt="productImage" onError={(e) => {
                                if (imgUrl) {
                                    this.setState(prevState => {
                                        return {
                                            imageError: true
                                        };
                                    });
                                }
                            }} />
                        </figure>}
                        <br />
                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={this.handleCancelButton}
                                className="btn btn--secondary btn-yes editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.combo_modal.cancel_html) }}></button>

                            <button
                                onClick={this.handleSubmitButton}
                                className="btn btn-yes editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.combo_modal.confirm_html) }}></button>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

const mapStateToProps = state => {
    return {
        currencySymbol: state.app.config.currencySymbol,
        inventoryItemID: state.ui.modal.data.inventoryItemID,
        product: getProductByInventoryItemID(state, state.ui.modal.data.inventoryItemID),
        comboGroup: getComboGroupByInventoryItemID(state, state.ui.modal.data.inventoryItemID)
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal
	}
)(ModalCombo));
